import { CBH_DATE_FORMAT } from "@clipboard-health/date-time/src/lib/format/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, FormControl, FormHelperText, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { differenceInDays, endOfDay, format, startOfDay, startOfYear } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useSendProofOfEarningsEmail } from "./api/useSendProofOfEarningsEmail";

const proofOfEarningsSchema = z
  .object({
    start: z.date().transform((d) => startOfDay(d)),
    end: z.date().transform((d) => endOfDay(d)),
  })
  .superRefine((data, ctx) => {
    if (data.end < data.start) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "End date must be after start date",
        path: ["end"],
      });
    }

    const eod = endOfDay(new Date());

    if (data.end > eod) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "End date cannot be after today",
        path: ["end"],
      });
    }
  });

function ProofOfEarningsPageWithHeader({ children }: { children: React.ReactNode }) {
  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Proof of Earnings"
          leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.PAYROLL} />}
        />
      }
    >
      {children}
    </PageWithHeader>
  );
}

export function ProofOfEarningsPage() {
  const { control, formState, watch, handleSubmit } = useForm({
    defaultValues: {
      start: startOfYear(new Date()),
      end: endOfDay(new Date()),
    },
    resolver: zodResolver(proofOfEarningsSchema),
  });
  const [start, end] = watch(["start", "end"]);
  const { showErrorToast, showInfoToast, showSuccessToast } = useToast();
  const sendProofOfEarningsEmail = useSendProofOfEarningsEmail();

  const { data: agent, status: agentProfileStatus } = useAgentProfile();

  const sendEmail = async (data: z.infer<typeof proofOfEarningsSchema>) => {
    if (agentProfileStatus !== "success") {
      showErrorToast("Could not load account details. Please try again later.");
      return;
    }

    showInfoToast("Sending email...");

    try {
      const result = await sendProofOfEarningsEmail.mutateAsync({
        start: data.start,
        end: data.end,
      });

      if (!result.data?.success) {
        throw new Error("Could not send email.");
      }

      showSuccessToast(`Proof of earnings sent to ${agent.email}`);
    } catch {
      showErrorToast("Could not send email. Please try again later.");
      return;
    }

    logEvent(USER_EVENTS.EMAIL_PAYSTUB, {
      startDate: format(data.start, "yyyy-MM-dd"),
      endDate: format(data.end, "yyyy-MM-dd"),
      dateRange: differenceInDays(data.end, data.start),
      userId: agent.userId,
    });
  };

  if (agentProfileStatus === "loading") {
    return (
      <ProofOfEarningsPageWithHeader>
        <p>Loading...</p>
      </ProofOfEarningsPageWithHeader>
    );
  }

  if (agentProfileStatus === "error") {
    return (
      <ProofOfEarningsPageWithHeader>
        <p>Could not load account details. Please try again later.</p>
      </ProofOfEarningsPageWithHeader>
    );
  }

  return (
    <ProofOfEarningsPageWithHeader>
      <form onSubmit={handleSubmit(sendEmail)}>
        <Stack spacing={2}>
          <Controller
            control={control}
            name="start"
            render={({ field }) => (
              <FormControl error={Boolean(formState.errors.start)}>
                <DatePicker
                  {...field}
                  disableFuture
                  label="Start Date"
                  format={CBH_DATE_FORMAT}
                  maxDate={end}
                />
                {formState.errors.start && (
                  <FormHelperText>{formState.errors.start.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="end"
            render={({ field }) => (
              <FormControl error={Boolean(formState.errors.end)}>
                <DatePicker
                  {...field}
                  disableFuture
                  label="End Date"
                  format={CBH_DATE_FORMAT}
                  minDate={start}
                />
                {formState.errors.end && (
                  <FormHelperText>{formState.errors.end.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            style={{ width: "100%" }}
            disabled={sendProofOfEarningsEmail.isLoading}
          >
            Email me my proof of earnings
          </Button>
        </Stack>
      </form>
    </ProofOfEarningsPageWithHeader>
  );
}
