import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import { environmentConfig } from "@src/appV2/environment";
import { type ReactElement } from "react";

import { AccountsDocumentPendingIcon } from "../statusIcons/Pending";
import { DocumentRequirementType } from "./types";

interface RequirementStatusProps {
  title: string;
  icon?: ReactElement;
  isCollapsible: boolean;
  description: string;
  actionIsRequired: boolean;
}

export const REQUIREMENT_STATUS_DATA: Record<DocumentRequirementType, RequirementStatusProps> = {
  [DocumentRequirementType.EXPIRED]: {
    title: "Expired",
    icon: <ErrorIcon color="error" />,
    isCollapsible: false,
    description: "",
    actionIsRequired: true,
  },
  [DocumentRequirementType.PENDING]: {
    title: "Pending",
    isCollapsible: true,
    icon: <AccountsDocumentPendingIcon />,
    description: "",
    actionIsRequired: false,
  },
  [DocumentRequirementType.MISSING]: {
    title: "Required by all facilities",
    isCollapsible: false,
    description:
      "You need to fulfill all of the requirements in this section in order to book shifts.",
    actionIsRequired: true,
  },
  [DocumentRequirementType.COMPLETED]: {
    title: "Completed",
    icon: <CheckCircleOutlineIcon color="success" />,
    isCollapsible: true,
    description: "",
    actionIsRequired: false,
  },
  [DocumentRequirementType.MISCELLANEOUS]: {
    title: "Miscellaneous",
    isCollapsible: false,
    description: "",
    actionIsRequired: true,
  },
  [DocumentRequirementType.OPTIONAL]: {
    title: "Optional",
    isCollapsible: false,
    description: "",
    actionIsRequired: true,
  },
  [DocumentRequirementType.REJECTED]: {
    title: "Rejected",
    icon: <ErrorIcon color="error" />,
    isCollapsible: false,
    description: "",
    actionIsRequired: true,
  },
  [DocumentRequirementType.REQUIRED_BY_FACILITIES]: {
    title: "Required for Some Facilities",
    isCollapsible: false,
    description:
      "Some facilities require additional documentation. Unlock more shifts by fulfilling the requirements in this section.",
    actionIsRequired: true,
  },
} as const;

export const PROFESSIONAL_REFERENCE_REQUIREMENT_ID = "5ca3b7f50648590016b245e2";

export const CHECKR_REQUIREMENT_ID = "5b9327c238aeaa0014c3688d";

export const PROFESSIONAL_REFERENCE_URL_APP_V1 = "/home/professional-references";

export const NUMBER_OF_DAYS_TO_SHOW_SOON_TO_EXPIRE_NOTICE = 30;

export const DEFAULT_DISTANCE_IN_MILES = 150;

export const ACCOUNT_ROUTE_V1 = "/home/account";

export const CHECKR_APPLICATION_STATUS_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/checkr/application-status`;
export const RESEND_CHECKR_APPLICATION_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/checkr/create-invitation`;

export const SEGMENT_DOCUMENT_CONTEXT = "documents";
